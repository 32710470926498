(function(){function r(e,n,t){function o(i,f){if(!n[i]){if(!e[i]){var c="function"==typeof require&&require;if(!f&&c)return c(i,!0);if(u)return u(i,!0);var a=new Error("Cannot find module '"+i+"'");throw a.code="MODULE_NOT_FOUND",a}var p=n[i]={exports:{}};e[i][0].call(p.exports,function(r){var n=e[i][1][r];return o(n||r)},p,p.exports,r,e,n,t)}return n[i].exports}for(var u="function"==typeof require&&require,i=0;i<t.length;i++)o(t[i]);return o}return r})()({1:[function(require,module,exports){
'use strict';

var Menu = function Menu(args) {
	var that = this;
	this.init = function () {
		this.cacheDom();
		bindEvents();
	};
	this.cacheDom = function () {
		this.header = document.querySelector(args.headerItem);
		this.menu = document.querySelector(args.menuWrapper);
		this.open = document.querySelector(args.menuOpen);
	};
	function OpenMenu() {
		that.header.classList.add('page-header--move');
		that.open.classList.toggle('is-active');
		that.menu.classList.toggle('is-active');
	}
	function bindEvents() {
		that.open.addEventListener('click', OpenMenu);
	}
};
module.exports = Menu;

},{}],2:[function(require,module,exports){
"use strict";

(function () {
	function r(e, n, t) {
		function o(i, f) {
			if (!n[i]) {
				if (!e[i]) {
					var c = "function" == typeof require && require;if (!f && c) return c(i, !0);if (u) return u(i, !0);var a = new Error("Cannot find module '" + i + "'");throw a.code = "MODULE_NOT_FOUND", a;
				}var p = n[i] = { exports: {} };e[i][0].call(p.exports, function (r) {
					var n = e[i][1][r];return o(n || r);
				}, p, p.exports, r, e, n, t);
			}return n[i].exports;
		}for (var u = "function" == typeof require && require, i = 0; i < t.length; i++) {
			o(t[i]);
		}return o;
	}return r;
})()({
	1: [function (require, module, exports) {
		'use strict';

		var Menu = function Menu(args) {
			var that = this;
			this.init = function () {
				this.cacheDom();
				bindEvents();
			};
			this.cacheDom = function () {
				this.header = document.querySelector(args.headerItem);
				this.menu = document.querySelector(args.menuWrapper);
				this.open = document.querySelector(args.menuOpen);
			};
			function OpenMenu() {
				that.header.classList.add('page-header--move');
				that.open.classList.toggle('is-active');
				that.menu.classList.toggle('is-active');
			}
			function bindEvents() {
				that.open.addEventListener('click', OpenMenu);
			}
		};
		module.exports = Menu;
	}, {}], 2: [function (require, module, exports) {
		'use strict';

		var _menu = require('./modules/menu.js');

		var _menu2 = _interopRequireDefault(_menu);

		function _interopRequireDefault(obj) {
			return obj && obj.__esModule ? obj : { default: obj };
		}

		var menu = new _menu2.default({
			headerItem: '.page-header',
			menuOpen: '.js-mobile',
			menuWrapper: ".js-menu"
		});
		menu.init();

		// try and screen functions
		try {
			var slider = new Swiper('.slider', {
				loop: true,
				autoplay: {
					delay: 2500
				},
				pagination: {
					el: '.slide-pagination',
					clickable: true,
					bulletClass: 'paggination-bullet',
					bulletActiveClass: 'paggination-bullet--active',
					renderBullet: function renderBullet(index, className) {
						return '<span class="' + className + '">' + '</span>';
					}
				}
			});
		} catch (error) {}

		try {
			var blog_slider = new Swiper('.blog-slider', {
				loop: true,
				pagination: {
					el: '.blog-pagination',
					clickable: true,
					bulletClass: 'paggination-blog-bullet',
					bulletActiveClass: 'paggination-blog-bullet--active',
					renderBullet: function renderBullet(index, className) {
						return '<span class="' + className + '">' + '</span>';
					}
				}
			});
		} catch (error) {}

		window.onscroll = function () {
			move_header();
		};
		var header = document.querySelector('.page-header');
		var sticky = header.offsetTop;
		function move_header() {
			if (window.pageYOffset > sticky) {
				header.classList.add('page-header--move');
			} else {
				header.classList.remove('page-header--move');
			}
		}
	}, { "./modules/menu.js": 1 }]
}, {}, [2]);

var cookiebox = document.querySelector('.cookie');
var coockieclose = document.querySelector('.cookie-close');

window.addEventListener('load', function () {
	if (document.cookie.indexOf("closepopup") == -1) {
		cookiebox.classList.add('is-active');
	}
});

coockieclose.addEventListener('click', function (event) {
	cookiebox.classList.remove('is-active');
	document.cookie = "closepopup=closepopup";
});

},{"./modules/menu.js":1}]},{},[2]);
